@import "../../../../scss/legacyScaleFunctions.scss";

$container-box-shadow-hd: 0px 1px 12px rgba(0, 0, 0, 0.5);
$container-border-radius-hd: 5px;
$container-width-hd: 356px;

$asset-information-container-padding-hd: 10px 10px 20px 15px;

$name-font-size-hd: 15px;
$name-margin-hd: 8px 0 0;

$category-and-floor-font-size-hd: 14px;
$category-and-floor-margin-hd: 2px 0 0;

$close-icon-button-size-hd: 32px;
$close-icon-svg-size-hd: 15px;
$close-icon-margin-hd: 0 0 0 6px;

$asset-information-margin-hd: 12px 0 0;

$information-row-gap-hd: 10px;
$information-row-margin-hd: 0 0 10px;
$information-row-svg-size-hd: 20px;
$information-row-text-font-size-hd: 14px;

$scan-container-padding-hd: 10px 8px;
$scan-container-width-hd: 120px;

$cta-heading-font-size-hd: 15px;
$cta-heading-margin-hd: 0 0 12px;

$qr-code-container-border-radius-hd: 2px;
$qr-code-container-padding-hd: 12px;

$qr-code-span-font-size-hd: 7px;
$qr-code-span-margin-hd: 0 0 5px;

.container {
  display: flex;
  align-items: stretch;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  overflow: hidden;
  line-height: 1;

  .assetInformationContainer {
    flex: 1;

    .heading {
      display: flex;
      justify-content: space-between;

      .name {
        font-weight: 500;
      }

      .category,
      .floorName {
        font-weight: 400;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .assetInformation {
      .informationRow {
        display: flex;
        align-items: center;
        word-break: break-word;

        &:last-child {
          margin: 0;
        }

        & > svg {
          flex-shrink: 0;
          align-self: flex-start;
        }

        & > p {
          font-weight: 400;
          margin: 0;

          &.lineBreak {
            white-space: pre-line;
          }
        }
      }
    }
  }

  .scanContainer {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ff5a00;

    .ctaHeading {
      font-weight: 700;
      text-align: center;
      color: #ffffff;
    }

    .qrCodeContainer {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;

      & > span {
        font-weight: 500;
        text-align: center;
      }
    }
  }

  // Light theme
  &.light {
    background-color: #ffffff;
    color: #212121;

    .assetInformationContainer {
      .heading {
        .category,
        .floorName {
          color: #757575;
        }
      }
    }
  }

  // Dark theme
  &.dark {
    background-color: #212121;
    color: #ffffff;

    .scanContainer {
      .qrCodeContainer {
        & > span {
          color: #212121;
        }
      }
    }
  }

  // Small styles
  &.small {
    box-shadow: $container-box-shadow-hd;
    border-radius: $container-border-radius-hd;
    width: $container-width-hd;

    .assetInformationContainer {
      padding: $asset-information-container-padding-hd;

      .heading {
        .name {
          font-size: $name-font-size-hd;
          margin: $name-margin-hd;
        }

        .category,
        .floorName {
          font-size: $category-and-floor-font-size-hd;
          margin: $category-and-floor-margin-hd;
        }

        .closeIcon {
          width: $close-icon-button-size-hd;
          height: $close-icon-button-size-hd;
          margin: $close-icon-margin-hd;

          & > svg {
            width: $close-icon-svg-size-hd;
            height: $close-icon-svg-size-hd;
          }
        }
      }

      .assetInformation {
        margin: $asset-information-margin-hd;

        .informationRow {
          gap: $information-row-gap-hd;
          margin: $information-row-margin-hd;

          &:last-child {
            margin: 0;
          }

          & > svg {
            width: $information-row-svg-size-hd;
            height: $information-row-svg-size-hd;
          }

          & > p {
            font-size: $information-row-text-font-size-hd;
          }
        }
      }
    }

    .scanContainer {
      padding: $scan-container-padding-hd;
      width: $scan-container-width-hd;

      .ctaHeading {
        font-size: $cta-heading-font-size-hd;
        margin: $cta-heading-margin-hd;
      }

      .qrCodeContainer {
        border-radius: $qr-code-container-border-radius-hd;
        padding: $qr-code-container-padding-hd;

        & > span {
          font-size: $qr-code-span-font-size-hd;
          margin: $qr-code-span-margin-hd;
        }
      }
    }
  }

  // Medium styles
  &.medium {
    box-shadow: scaleMedium($container-box-shadow-hd);
    border-radius: scaleMedium($container-border-radius-hd);
    width: scaleMedium($container-width-hd);

    .assetInformationContainer {
      padding: scaleMedium($asset-information-container-padding-hd);

      .heading {
        .name {
          font-size: scaleMedium($name-font-size-hd);
          margin: scaleMedium($name-margin-hd);
        }

        .category,
        .floorName {
          font-size: scaleMedium($category-and-floor-font-size-hd);
          margin: scaleMedium($category-and-floor-margin-hd);
        }

        .closeIcon {
          width: scaleMedium($close-icon-button-size-hd);
          height: scaleMedium($close-icon-button-size-hd);
          margin: scaleMedium($close-icon-margin-hd);

          & > svg {
            width: scaleMedium($close-icon-svg-size-hd);
            height: scaleMedium($close-icon-svg-size-hd);
          }
        }
      }

      .assetInformation {
        margin: scaleMedium($asset-information-margin-hd);

        .informationRow {
          gap: scaleMedium($information-row-gap-hd);
          margin: scaleMedium($information-row-margin-hd);

          &:last-child {
            margin: 0;
          }

          & > svg {
            width: scaleMedium($information-row-svg-size-hd);
            height: scaleMedium($information-row-svg-size-hd);
          }

          & > p {
            font-size: scaleMedium($information-row-text-font-size-hd);
          }
        }
      }
    }

    .scanContainer {
      padding: scaleMedium($scan-container-padding-hd);
      width: scaleMedium($scan-container-width-hd);

      .ctaHeading {
        font-size: scaleMedium($cta-heading-font-size-hd);
        margin: scaleMedium($cta-heading-margin-hd);
      }

      .qrCodeContainer {
        border-radius: scaleMedium($qr-code-container-border-radius-hd);
        padding: scaleMedium($qr-code-container-padding-hd);

        & > span {
          font-size: scaleMedium($qr-code-span-font-size-hd);
          margin: scaleMedium($qr-code-span-margin-hd);
        }
      }
    }
  }

  // Large styles
  &.large {
    box-shadow: scaleLarge($container-box-shadow-hd);
    border-radius: scaleLarge($container-border-radius-hd);
    width: scaleLarge($container-width-hd);

    .assetInformationContainer {
      padding: scaleLarge($asset-information-container-padding-hd);

      .heading {
        .name {
          font-size: scaleLarge($name-font-size-hd);
          margin: scaleLarge($name-margin-hd);
        }

        .category,
        .floorName {
          font-size: scaleLarge($category-and-floor-font-size-hd);
          margin: scaleLarge($category-and-floor-margin-hd);
        }

        .closeIcon {
          width: scaleLarge($close-icon-button-size-hd);
          height: scaleLarge($close-icon-button-size-hd);
          margin: scaleLarge($close-icon-margin-hd);

          & > svg {
            width: scaleLarge($close-icon-svg-size-hd);
            height: scaleLarge($close-icon-svg-size-hd);
          }
        }
      }

      .assetInformation {
        margin: scaleLarge($asset-information-margin-hd);

        .informationRow {
          gap: scaleLarge($information-row-gap-hd);
          margin: scaleLarge($information-row-margin-hd);

          &:last-child {
            margin: 0;
          }

          & > svg {
            width: scaleLarge($information-row-svg-size-hd);
            height: scaleLarge($information-row-svg-size-hd);
          }

          & > p {
            font-size: scaleLarge($information-row-text-font-size-hd);
          }
        }
      }
    }

    .scanContainer {
      padding: scaleLarge($scan-container-padding-hd);
      width: scaleLarge($scan-container-width-hd);

      .ctaHeading {
        font-size: scaleLarge($cta-heading-font-size-hd);
        margin: scaleLarge($cta-heading-margin-hd);
      }

      .qrCodeContainer {
        border-radius: scaleLarge($qr-code-container-border-radius-hd);
        padding: scaleLarge($qr-code-container-padding-hd);

        & > span {
          font-size: scaleLarge($qr-code-span-font-size-hd);
          margin: scaleLarge($qr-code-span-margin-hd);
        }
      }
    }
  }
}

@media (min-width: 2160px) {
  .container {
    // Small styles
    &.small {
      box-shadow: scaleDouble($container-box-shadow-hd);
      border-radius: scaleDouble($container-border-radius-hd);
      width: scaleDouble($container-width-hd);

      .assetInformationContainer {
        padding: scaleDouble($asset-information-container-padding-hd);

        .heading {
          .name {
            font-size: scaleDouble($name-font-size-hd);
            margin: scaleDouble($name-margin-hd);
          }

          .category,
          .floorName {
            font-size: scaleDouble($category-and-floor-font-size-hd);
            margin: scaleDouble($category-and-floor-margin-hd);
          }

          .closeIcon {
            width: scaleDouble($close-icon-button-size-hd);
            height: scaleDouble($close-icon-button-size-hd);
            margin: scaleDouble($close-icon-margin-hd);

            & > svg {
              width: scaleDouble($close-icon-svg-size-hd);
              height: scaleDouble($close-icon-svg-size-hd);
            }
          }
        }

        .assetInformation {
          margin: scaleDouble($asset-information-margin-hd);

          .informationRow {
            gap: scaleDouble($information-row-gap-hd);
            margin: scaleDouble($information-row-margin-hd);

            &:last-child {
              margin: 0;
            }

            & > svg {
              width: scaleDouble($information-row-svg-size-hd);
              height: scaleDouble($information-row-svg-size-hd);
            }

            & > p {
              font-size: scaleDouble($information-row-text-font-size-hd);
            }
          }
        }
      }

      .scanContainer {
        padding: scaleDouble($scan-container-padding-hd);
        width: scaleDouble($scan-container-width-hd);

        .ctaHeading {
          font-size: scaleDouble($cta-heading-font-size-hd);
          margin: scaleDouble($cta-heading-margin-hd);
        }

        .qrCodeContainer {
          border-radius: scaleDouble($qr-code-container-border-radius-hd);
          padding: scaleDouble($qr-code-container-padding-hd);

          & > span {
            font-size: scaleDouble($qr-code-span-font-size-hd);
            margin: scaleDouble($qr-code-span-margin-hd);
          }
        }
      }
    }

    // Medium styles
    &.medium {
      box-shadow: scaleDouble(scaleMedium($container-box-shadow-hd));
      border-radius: scaleDouble(scaleMedium($container-border-radius-hd));
      width: scaleDouble(scaleMedium($container-width-hd));

      .assetInformationContainer {
        padding: scaleDouble(
          scaleMedium($asset-information-container-padding-hd)
        );

        .heading {
          .name {
            font-size: scaleDouble(scaleMedium($name-font-size-hd));
            margin: scaleDouble(scaleMedium($name-margin-hd));
          }

          .category,
          .floorName {
            font-size: scaleDouble(
              scaleMedium($category-and-floor-font-size-hd)
            );
            margin: scaleDouble(scaleMedium($category-and-floor-margin-hd));
          }

          .closeIcon {
            width: scaleDouble(scaleMedium($close-icon-button-size-hd));
            height: scaleDouble(scaleMedium($close-icon-button-size-hd));
            margin: scaleDouble(scaleMedium($close-icon-margin-hd));

            & > svg {
              width: scaleDouble(scaleMedium($close-icon-svg-size-hd));
              height: scaleDouble(scaleMedium($close-icon-svg-size-hd));
            }
          }
        }

        .assetInformation {
          margin: scaleDouble(scaleMedium($asset-information-margin-hd));

          .informationRow {
            gap: scaleDouble(scaleMedium($information-row-gap-hd));
            margin: scaleDouble(scaleMedium($information-row-margin-hd));

            &:last-child {
              margin: 0;
            }

            & > svg {
              width: scaleDouble(scaleMedium($information-row-svg-size-hd));
              height: scaleDouble(scaleMedium($information-row-svg-size-hd));
            }

            & > p {
              font-size: scaleDouble(
                scaleMedium($information-row-text-font-size-hd)
              );
            }
          }
        }
      }

      .scanContainer {
        padding: scaleDouble(scaleMedium($scan-container-padding-hd));
        width: scaleDouble(scaleMedium($scan-container-width-hd));

        .ctaHeading {
          font-size: scaleDouble(scaleMedium($cta-heading-font-size-hd));
          margin: scaleDouble(scaleMedium($cta-heading-margin-hd));
        }

        .qrCodeContainer {
          border-radius: scaleDouble(
            scaleMedium($qr-code-container-border-radius-hd)
          );
          padding: scaleDouble(scaleMedium($qr-code-container-padding-hd));

          & > span {
            font-size: scaleDouble(scaleMedium($qr-code-span-font-size-hd));
            margin: scaleDouble(scaleMedium($qr-code-span-margin-hd));
          }
        }
      }
    }

    // Large styles
    &.large {
      box-shadow: scaleDouble(scaleLarge($container-box-shadow-hd));
      border-radius: scaleDouble(scaleLarge($container-border-radius-hd));
      width: scaleDouble(scaleLarge($container-width-hd));

      .assetInformationContainer {
        padding: scaleDouble(
          scaleLarge($asset-information-container-padding-hd)
        );

        .heading {
          .name {
            font-size: scaleDouble(scaleLarge($name-font-size-hd));
            margin: scaleDouble(scaleLarge($name-margin-hd));
          }

          .category,
          .floorName {
            font-size: scaleDouble(
              scaleLarge($category-and-floor-font-size-hd)
            );
            margin: scaleDouble(scaleLarge($category-and-floor-margin-hd));
          }

          .closeIcon {
            width: scaleDouble(scaleLarge($close-icon-button-size-hd));
            height: scaleDouble(scaleLarge($close-icon-button-size-hd));
            margin: scaleDouble(scaleLarge($close-icon-margin-hd));

            & > svg {
              width: scaleDouble(scaleLarge($close-icon-svg-size-hd));
              height: scaleDouble(scaleLarge($close-icon-svg-size-hd));
            }
          }
        }

        .assetInformation {
          margin: scaleDouble(scaleLarge($asset-information-margin-hd));

          .informationRow {
            gap: scaleDouble(scaleLarge($information-row-gap-hd));
            margin: scaleDouble(scaleLarge($information-row-margin-hd));

            &:last-child {
              margin: 0;
            }

            & > svg {
              width: scaleDouble(scaleLarge($information-row-svg-size-hd));
              height: scaleDouble(scaleLarge($information-row-svg-size-hd));
            }

            & > p {
              font-size: scaleDouble(
                scaleLarge($information-row-text-font-size-hd)
              );
            }
          }
        }
      }

      .scanContainer {
        padding: scaleDouble(scaleLarge($scan-container-padding-hd));
        width: scaleDouble(scaleLarge($scan-container-width-hd));

        .ctaHeading {
          font-size: scaleDouble(scaleLarge($cta-heading-font-size-hd));
          margin: scaleDouble(scaleLarge($cta-heading-margin-hd));
        }

        .qrCodeContainer {
          border-radius: scaleDouble(
            scaleLarge($qr-code-container-border-radius-hd)
          );
          padding: scaleDouble(scaleLarge($qr-code-container-padding-hd));

          & > span {
            font-size: scaleDouble(scaleLarge($qr-code-span-font-size-hd));
            margin: scaleDouble(scaleLarge($qr-code-span-margin-hd));
          }
        }
      }
    }
  }
}
