@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$modal-border-radius: 16px;
$modal-padding: 24px;
$modal-width: 400px;

$title-font-size: 28px;
$title-margin: 0 0 24px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      border-radius: call(get-function($scaleFunction), $modal-border-radius);
      padding: call(get-function($scaleFunction), $modal-padding);
      width: call(get-function($scaleFunction), $modal-width);

      .title {
        font-size: call(get-function($scaleFunction), $title-font-size);
        margin: call(get-function($scaleFunction), $title-margin);
      }
    }
  }
}

.container {
  &.accessibleHeight {
    .modal {
      transform: translateY(0);
    }
  }

  .modal {
    align-items: center;
    box-shadow:
      0px 0px 8px 0px rgba(0, 0, 0, 0.04),
      0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
    text-align: center;
    z-index: 1;
    transform: translateY(50%);

    .title {
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.28px;
    }

    /* Dark theme */
    &.dark {
      background: #212121;

      .title {
        color: #fafafa;
      }
    }

    /* Light theme */
    &.light {
      background: #fff;

      .title {
        color: #212121;
      }
    }

    @include buildSizes();
  }
}

@include scale-for-4k-media-query {
  .container {
    .modal {
      @include buildSizes($doubled-sizes);
    }
  }
}
