.container {
  display: flex;
  align-items: center;
  margin: 2px 0;
  animation: fadeIn 800ms ease-in-out forwards;
}

.icon {
  width: 17px;
  height: 17px;
  margin: 0 4px 0 0;
}

.text {
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
}

@media (min-width: 2160px) {
  .container {
    margin: 4px 0;
  }

  .icon {
    width: 34px;
    height: 34px;
    margin: 0 8px 0 0;
  }

  .text {
    font-size: 20px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
