@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$size: 56px;
$svg-size: 21px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      &,
      &::before {
        height: call(get-function($scaleFunction), $size);
        width: call(get-function($scaleFunction), $size);
      }

      svg {
        height: call(get-function($scaleFunction), $svg-size);
        width: call(get-function($scaleFunction), $svg-size);
      }
    }
  }
}

@mixin buildColors($bg, $color, $active-bg, $disabled-bg, $pulse-color) {
  @keyframes pulse {
    50% {
      color: $pulse-color;
    }
  }

  background: $bg;

  svg {
    color: $color;
  }

  &.searching {
    svg {
      animation: pulse 1s ease-in-out infinite;
    }
  }

  &.found {
    svg {
      color: #3c78ff;
    }
  }

  &:active {
    background: $active-bg;
  }

  &:disabled {
    background: $disabled-bg;
    cursor: auto;

    svg {
      color: #9e9e9e;
    }
  }

  &::before {
    background: $bg;
  }
}

.container {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);

  &::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    z-index: -1;
  }

  // Light theme
  &.light {
    @include buildColors(#fff, #212121, #21212114, #e0e0e0, rgba(0, 0, 0, 0.3));
  }

  // Dark theme
  &.dark {
    @include buildColors(
      #212121,
      #fff,
      #ffffff14,
      #606060,
      rgba(255, 255, 255, 0.3)
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
