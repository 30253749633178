@import "../../../../../../scss/common.scss";
@import "../../../../../../scss/newScaleFunctions.scss";

$border-radius: 10px;
$size: 48px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      border-radius: call(get-function($scaleFunction), $border-radius);
      height: call(get-function($scaleFunction), $size);
    }
  }
}

@mixin buildColors($color, $bg, $iconButtonBg, $activeBg, $iconActiveBg) {
  color: $color;
  background: $bg;

  &.iconButton {
    background: $iconButtonBg;
  }

  &.activeKey {
    background: $activeBg;
    transition: none;

    &.iconButton {
      background: $iconActiveBg;
    }
  }
}

.key {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  border: none;
  margin: 0;
  padding: 0;
  user-select: none;
  transition:
    background-color 200ms ease-in-out,
    color 200ms ease-in-out;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.25px;

  > * {
    pointer-events: none;
  }

  .verticalAlign {
    line-height: 100%;
    height: 60%;
  }

  &.light {
    @include buildColors(
      #424242,
      #fff,
      rgba(33, 33, 33, 0.12),
      rgba(33, 33, 33, 0.08),
      rgba(33, 33, 33, 0.2)
    );
  }

  &.dark {
    @include buildColors(
      #fafafa,
      #212121,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.2)
    );
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .key {
    @include buildSizes($doubled-sizes);
  }
}
