@import "../../../../scss/legacyScaleFunctions.scss";

$container-box-shadow-hd: 0px 1px 12px rgba(0, 0, 0, 0.5);
$container-border-radius-hd: 5px;

$wrapper-padding-hd: 10px 15px;

$name-font-size-hd: 15px;

$floor-font-size-hd: 14px;
$floor-margin-hd: 5px 0 0;

$icon-width-hd: 36px;
$icon-height-hd: 40px;

.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  overflow: hidden;
  line-height: 1;

  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      display: block;
      font-weight: 500;
    }

    .floor {
      display: block;
      font-weight: 400;
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Light styles
  &.light {
    background-color: #ffffff;

    .textWrapper {
      .name {
        color: #212121;
      }

      .floor {
        color: #757575;
      }
    }

    .iconWrapper {
      background-color: #f1f1f1;
      color: #212121;
    }
  }

  // Dark styles
  &.dark {
    background-color: #212121;

    .textWrapper {
      .name,
      .floor {
        color: #ffffff;
      }
    }

    .iconWrapper {
      background-color: #323232;
      color: #ffffff;
    }
  }

  // Small styles
  &.small {
    box-shadow: $container-box-shadow-hd;
    border-radius: $container-border-radius-hd;

    .textWrapper {
      padding: $wrapper-padding-hd;

      .name {
        font-size: $name-font-size-hd;
      }

      .floor {
        font-size: $floor-font-size-hd;
        margin: $floor-margin-hd;
      }
    }

    .iconWrapper {
      padding: $wrapper-padding-hd;

      .icon {
        width: $icon-width-hd;
        height: $icon-height-hd;
      }
    }
  }

  // Medium styles
  &.medium {
    box-shadow: scaleMedium($container-box-shadow-hd);
    border-radius: scaleMedium($container-border-radius-hd);

    .textWrapper {
      padding: scaleMedium($wrapper-padding-hd);

      .name {
        font-size: scaleMedium($name-font-size-hd);
      }

      .floor {
        font-size: scaleMedium($floor-font-size-hd);
        margin: scaleMedium($floor-margin-hd);
      }
    }

    .iconWrapper {
      padding: scaleMedium($wrapper-padding-hd);

      .icon {
        width: scaleMedium($icon-width-hd);
        height: scaleMedium($icon-height-hd);
      }
    }
  }

  // Large styles
  &.large {
    box-shadow: scaleLarge($container-box-shadow-hd);
    border-radius: scaleLarge($container-border-radius-hd);

    .textWrapper {
      padding: scaleLarge($wrapper-padding-hd);

      .name {
        font-size: scaleLarge($name-font-size-hd);
      }

      .floor {
        font-size: scaleLarge($floor-font-size-hd);
        margin: scaleLarge($floor-margin-hd);
      }
    }

    .iconWrapper {
      padding: scaleLarge($wrapper-padding-hd);

      .icon {
        width: scaleLarge($icon-width-hd);
        height: scaleLarge($icon-height-hd);
      }
    }
  }
}

@media (min-width: 2160px) {
  .container {
    // Small styles
    &.small {
      box-shadow: scaleDouble($container-box-shadow-hd);
      border-radius: scaleDouble($container-border-radius-hd);

      .textWrapper {
        padding: scaleDouble($wrapper-padding-hd);

        .name {
          font-size: scaleDouble($name-font-size-hd);
        }

        .floor {
          font-size: scaleDouble($floor-font-size-hd);
          margin: scaleDouble($floor-margin-hd);
        }
      }

      .iconWrapper {
        padding: scaleDouble($wrapper-padding-hd);

        .icon {
          width: scaleDouble($icon-width-hd);
          height: scaleDouble($icon-height-hd);
        }
      }
    }

    // Medium styles
    &.medium {
      box-shadow: scaleDouble(scaleMedium($container-box-shadow-hd));
      border-radius: scaleDouble(scaleMedium($container-border-radius-hd));

      .textWrapper {
        padding: scaleDouble(scaleMedium($wrapper-padding-hd));

        .name {
          font-size: scaleDouble(scaleMedium($name-font-size-hd));
        }

        .floor {
          font-size: scaleDouble(scaleMedium($floor-font-size-hd));
          margin: scaleDouble(scaleMedium($floor-margin-hd));
        }
      }

      .iconWrapper {
        padding: scaleDouble(scaleMedium($wrapper-padding-hd));

        .icon {
          width: scaleDouble(scaleMedium($icon-width-hd));
          height: scaleDouble(scaleMedium($icon-height-hd));
        }
      }
    }

    // Large styles
    &.large {
      box-shadow: scaleDouble(scaleLarge($container-box-shadow-hd));
      border-radius: scaleDouble(scaleLarge($container-border-radius-hd));

      .textWrapper {
        padding: scaleDouble(scaleLarge($wrapper-padding-hd));

        .name {
          font-size: scaleDouble(scaleLarge($name-font-size-hd));
        }

        .floor {
          font-size: scaleDouble(scaleLarge($floor-font-size-hd));
          margin: scaleDouble(scaleLarge($floor-margin-hd));
        }
      }

      .iconWrapper {
        padding: scaleDouble(scaleLarge($wrapper-padding-hd));

        .icon {
          width: scaleDouble(scaleLarge($icon-width-hd));
          height: scaleDouble(scaleLarge($icon-height-hd));
        }
      }
    }
  }
}
