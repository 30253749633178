/* Gotham font start */

@font-face {
  font-family: "Gotham";
  src: local("Gotham-Book"), url("./Gotham/Gotham-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-BookItalic"),
    url("./Gotham/Gotham-BookItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-LightItalic"),
    url("./Gotham/Gotham-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-Light"),
    url("./Gotham/Gotham-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-Medium"),
    url("./Gotham/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-MediumItalic"),
    url("./Gotham/Gotham-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-Bold"), url("./Gotham/Gotham-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham-BoldItalic"),
    url("./Gotham/Gotham-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* Gotham font end */

/* IBM Plex Sans font start */

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"),
    url("./IBM/IBMPlexSans-MediumItalic.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-MediumItalic.woff") format("woff"),
    url("./IBM/IBMPlexSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"),
    url("./IBM/IBMPlexSans-Medium.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-Medium.woff") format("woff"),
    url("./IBM/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("./IBM/IBMPlexSans-Bold.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-Bold.woff") format("woff"),
    url("./IBM/IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
    url("./IBM/IBMPlexSans-BoldItalic.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-BoldItalic.woff") format("woff"),
    url("./IBM/IBMPlexSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"),
    url("./IBM/IBMPlexSans-LightItalic.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-LightItalic.woff") format("woff"),
    url("./IBM/IBMPlexSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"),
    url("./IBM/IBMPlexSans-Light.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-Light.woff") format("woff"),
    url("./IBM/IBMPlexSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("./IBM/IBMPlexSans.woff2") format("woff2"),
    url("./IBM/IBMPlexSans.woff") format("woff"),
    url("./IBM/IBMPlexSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
    url("./IBM/IBMPlexSans-Italic.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-Italic.woff") format("woff"),
    url("./IBM/IBMPlexSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"),
    url("./IBM/IBMPlexSans-SemiBold.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-SemiBold.woff") format("woff"),
    url("./IBM/IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans SemiBold Italic"),
    local("IBMPlexSans-SemiBoldItalic"),
    url("./IBM/IBMPlexSans-SemiBoldItalic.woff2") format("woff2"),
    url("./IBM/IBMPlexSans-SemiBoldItalic.woff") format("woff"),
    url("./IBM/IBMPlexSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* IBM Plex Sans font end */

/* Noto Sans font start */

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Black Italic"), local("NotoSans-BlackItalic"),
    url("./NotoSans/NotoSans-BlackItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-BlackItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Bold Italic"), local("NotoSans-BoldItalic"),
    url("./NotoSans/NotoSans-BoldItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-BoldItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Bold"), local("NotoSans-Bold"),
    url("./NotoSans/NotoSans-Bold.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Bold.woff") format("woff"),
    url("./NotoSans/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Black"), local("NotoSans-Black"),
    url("./NotoSans/NotoSans-Black.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Black.woff") format("woff"),
    url("./NotoSans/NotoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans ExtraLight"), local("NotoSans-ExtraLight"),
    url("./NotoSans/NotoSans-ExtraLight.woff2") format("woff2"),
    url("./NotoSans/NotoSans-ExtraLight.woff") format("woff"),
    url("./NotoSans/NotoSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans ExtraBold Italic"), local("NotoSans-ExtraBoldItalic"),
    url("./NotoSans/NotoSans-ExtraBoldItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-ExtraBoldItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans ExtraBold"), local("NotoSans-ExtraBold"),
    url("./NotoSans/NotoSans-ExtraBold.woff2") format("woff2"),
    url("./NotoSans/NotoSans-ExtraBold.woff") format("woff"),
    url("./NotoSans/NotoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans ExtraLight Italic"), local("NotoSans-ExtraLightItalic"),
    url("./NotoSans/NotoSans-ExtraLightItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-ExtraLightItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Italic"), local("NotoSans-Italic"),
    url("./NotoSans/NotoSans-Italic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Italic.woff") format("woff"),
    url("./NotoSans/NotoSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Light"), local("NotoSans-Light"),
    url("./NotoSans/NotoSans-Light.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Light.woff") format("woff"),
    url("./NotoSans/NotoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans SemiBold"), local("NotoSans-SemiBold"),
    url("./NotoSans/NotoSans-SemiBold.woff2") format("woff2"),
    url("./NotoSans/NotoSans-SemiBold.woff") format("woff"),
    url("./NotoSans/NotoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans SemiBold Italic"), local("NotoSans-SemiBoldItalic"),
    url("./NotoSans/NotoSans-SemiBoldItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-SemiBoldItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Medium Italic"), local("NotoSans-MediumItalic"),
    url("./NotoSans/NotoSans-MediumItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-MediumItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Medium"), local("NotoSans-Medium"),
    url("./NotoSans/NotoSans-Medium.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Medium.woff") format("woff"),
    url("./NotoSans/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Light Italic"), local("NotoSans-LightItalic"),
    url("./NotoSans/NotoSans-LightItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-LightItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Regular"), local("NotoSans-Regular"),
    url("./NotoSans/NotoSans-Regular.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Regular.woff") format("woff"),
    url("./NotoSans/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Thin Italic"), local("NotoSans-ThinItalic"),
    url("./NotoSans/NotoSans-ThinItalic.woff2") format("woff2"),
    url("./NotoSans/NotoSans-ThinItalic.woff") format("woff"),
    url("./NotoSans/NotoSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans Thin"), local("NotoSans-Thin"),
    url("./NotoSans/NotoSans-Thin.woff2") format("woff2"),
    url("./NotoSans/NotoSans-Thin.woff") format("woff"),
    url("./NotoSans/NotoSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* Noto Sans font end */

/* Noto Serif font start */

@font-face {
  font-family: "Noto Serif";
  src: local("Noto Serif Bold Italic"), local("NotoSerif-BoldItalic"),
    url("./NotoSerif/NotoSerif-BoldItalic.woff2") format("woff2"),
    url("./NotoSerif/NotoSerif-BoldItalic.woff") format("woff"),
    url("./NotoSerif/NotoSerif-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  src: local("Noto Serif Italic"), local("NotoSerif-Italic"),
    url("./NotoSerif/NotoSerif-Italic.woff2") format("woff2"),
    url("./NotoSerif/NotoSerif-Italic.woff") format("woff"),
    url("./NotoSerif/NotoSerif-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  src: local("Noto Serif Bold"), local("NotoSerif-Bold"),
    url("./NotoSerif/NotoSerif-Bold.woff2") format("woff2"),
    url("./NotoSerif/NotoSerif-Bold.woff") format("woff"),
    url("./NotoSerif/NotoSerif-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  src: local("Noto Serif"), local("NotoSerif"),
    url("./NotoSerif/NotoSerif.woff2") format("woff2"),
    url("./NotoSerif/NotoSerif.woff") format("woff"),
    url("./NotoSerif/NotoSerif.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Noto Serif font end */
