@import "../../scss/legacyScaleFunctions.scss";

.container {
  // Light theme
  &.light {
    background-color: #fafafa;
    color: #212121;
  }

  // Dark theme
  &.dark {
    background-color: #212121;
    color: #ffffff;
  }

  .heading {
    font-weight: 600;
    margin: 0;
  }

  .columnWrapper {
    display: grid;

    .iconContainer {
      img {
        height: auto;
      }

      span {
        font-weight: 400;
        line-height: 1.15;
      }
    }
  }

  // Small styles
  &.small {
    border-radius: 6px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px 20px;
    max-width: 400px;

    @media (min-width: 2160px) {
      border-radius: 12px;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
      padding: 30px 30px 40px;
      max-width: 800px;
    }

    .heading {
      font-size: 16px;

      @media (min-width: 2160px) {
        font-size: 32px;
      }
    }

    .columnWrapper {
      gap: 20px;
      margin: 10px 0 0;

      @media (min-width: 2160px) {
        gap: 40px;
        margin: 20px 0 0;
      }

      &.oneColumn {
        grid-template-columns: 110px;

        @media (min-width: 2160px) {
          grid-template-columns: 220px;
        }
      }

      &.twoColumns {
        grid-template-columns: repeat(2, 110px);

        @media (min-width: 2160px) {
          grid-template-columns: repeat(2, 220px);
        }
      }

      &.threeColumns {
        grid-template-columns: repeat(3, 110px);

        @media (min-width: 2160px) {
          grid-template-columns: repeat(3, 220px);
        }
      }

      .iconContainer {
        min-height: 17px;
        margin: 0 0 5px;

        @media (min-width: 2160px) {
          min-height: 34px;
          margin: 0 0 10px;
        }

        &:last-child {
          margin: 0;
        }

        img {
          width: 17px;

          @media (min-width: 2160px) {
            width: 34px;
          }
        }

        span {
          font-size: 12px;

          @media (min-width: 2160px) {
            font-size: 24px;
          }
        }
      }
    }
  }

  // Medium styles
  &.medium {
    border-radius: scaleMedium(6px);
    box-shadow: scaleMedium(0px 2px 15px 0px rgba(0, 0, 0, 0.25));
    padding: scaleMedium(15px 15px 20px);
    max-width: scaleMedium(400px);

    .heading {
      font-size: scaleMedium(16px);
    }

    .columnWrapper {
      gap: scaleMedium(20px);
      margin: scaleMedium(10px 0 0);

      &.oneColumn {
        grid-template-columns: scaleMedium(110px);
      }

      &.twoColumns {
        grid-template-columns: repeat(2, scaleMedium(110px));
      }

      &.threeColumns {
        grid-template-columns: repeat(3, scaleMedium(110px));
      }

      .iconContainer {
        margin: scaleMedium(0 0 5px);
        min-height: scaleMedium(17px);

        &:last-child {
          margin: 0;
        }

        img {
          width: scaleMedium(17px);
        }

        span {
          font-size: scaleMedium(12px);
        }
      }
    }
  }

  // Large styles
  &.large {
    border-radius: scaleLarge(6px);
    box-shadow: scaleLarge(0px 2px 15px 0px rgba(0, 0, 0, 0.25));
    padding: scaleLarge(15px 15px 20px);
    max-width: scaleLarge(400px);

    .heading {
      font-size: scaleLarge(16px);
    }

    .columnWrapper {
      gap: scaleLarge(20px);
      margin: scaleLarge(10px 0 0);

      &.oneColumn {
        grid-template-columns: scaleLarge(110px);
      }

      &.twoColumns {
        grid-template-columns: repeat(2, scaleLarge(110px));
      }

      &.threeColumns {
        grid-template-columns: repeat(3, scaleLarge(110px));
      }

      .iconContainer {
        margin: scaleLarge(0 0 5px);
        min-height: scaleLarge(17px);

        &:last-child {
          margin: 0;
        }

        img {
          width: scaleLarge(17px);
        }

        span {
          font-size: scaleLarge(12px);
        }
      }
    }
  }
}

@media (min-width: 2160px) {
  .container {
    // Small styles
    &.small {
      border-radius: 12px;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
      padding: 30px 30px 40px;
      max-width: 800px;

      .heading {
        font-size: 32px;
      }

      .columnWrapper {
        gap: 40px;
        margin: 20px 0 0;

        &.oneColumn {
          grid-template-columns: 220px;
        }

        &.twoColumns {
          grid-template-columns: repeat(2, 220px);
        }

        &.threeColumns {
          grid-template-columns: repeat(3, 220px);
        }

        .iconContainer {
          min-height: 34px;
          margin: 0 0 10px;

          &:last-child {
            margin: 0;
          }

          img {
            width: 34px;
          }

          span {
            font-size: 24px;
          }
        }
      }
    }

    // Medium styles
    &.medium {
      border-radius: scaleDouble(scaleMedium(6px));
      box-shadow: scaleDouble(
        scaleMedium(0px 2px 15px 0px rgba(0, 0, 0, 0.25))
      );
      padding: scaleDouble(scaleMedium(15px 15px 20px));
      max-width: scaleDouble(scaleMedium(400px));

      .heading {
        font-size: scaleDouble(scaleMedium(16px));
      }

      .columnWrapper {
        gap: scaleDouble(scaleMedium(20px));
        margin: scaleDouble(scaleMedium(10px 0 0));

        &.oneColumn {
          grid-template-columns: scaleDouble(scaleMedium(110px));
        }

        &.twoColumns {
          grid-template-columns: repeat(2, scaleDouble(scaleMedium(110px)));
        }

        &.threeColumns {
          grid-template-columns: repeat(3, scaleDouble(scaleMedium(110px)));
        }

        .iconContainer {
          margin: scaleDouble(scaleMedium(0 0 5px));
          min-height: scaleDouble(scaleMedium(17px));

          &:last-child {
            margin: 0;
          }

          img {
            width: scaleDouble(scaleMedium(17px));
          }

          span {
            font-size: scaleDouble(scaleMedium(12px));
          }
        }
      }
    }

    // Large styles
    &.large {
      border-radius: scaleDouble(scaleLarge(6px));
      box-shadow: scaleDouble(scaleLarge(0px 2px 15px 0px rgba(0, 0, 0, 0.25)));
      padding: scaleDouble(scaleLarge(15px 15px 20px));
      max-width: scaleDouble(scaleLarge(400px));

      .heading {
        font-size: scaleDouble(scaleLarge(16px));
      }

      .columnWrapper {
        gap: scaleDouble(scaleLarge(20px));
        margin: scaleDouble(scaleLarge(10px 0 0));

        &.oneColumn {
          grid-template-columns: scaleDouble(scaleLarge(110px));
        }

        &.twoColumns {
          grid-template-columns: repeat(2, scaleDouble(scaleLarge(110px)));
        }

        &.threeColumns {
          grid-template-columns: repeat(3, scaleDouble(scaleLarge(110px)));
        }

        .iconContainer {
          margin: scaleDouble(scaleLarge(0 0 5px));
          min-height: scaleDouble(scaleLarge(17px));

          &:last-child {
            margin: 0;
          }

          img {
            width: scaleDouble(scaleLarge(17px));
          }

          span {
            font-size: scaleDouble(scaleLarge(12px));
          }
        }
      }
    }
  }
}
