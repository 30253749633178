.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
  padding: 32px;
  z-index: 5;
}

.heading {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  animation: fadeIn 800ms ease-in-out forwards;
}

.subheading {
  font-size: 40px;
  font-weight: 400;
  margin: 0;
  animation: fadeIn 800ms ease-in-out forwards;
}

.time {
  font-size: 40px;
  margin: 0;
  text-align: right;
  animation: fadeIn 800ms ease-in-out forwards;
}

.icon {
  height: 40px;
  width: auto;
  margin: 0 10px 0 0;
}

.withIcon {
  display: flex;
  align-items: center;
}

@media (min-width: 2160px) {
  .container {
    padding: 64px;
  }

  .heading,
  .subheading,
  .time {
    font-size: 80px;
  }

  .icon {
    height: 80px;
    margin: 0 20px 0 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
