@import "../../scss/common.scss";
@import "../../scss/newScaleFunctions.scss";

$default-horizon-line-height: 715px;
$accessible-horizon-line-height: 120px;

$button-bottom: 40px;

@mixin blurBackdrop {
  &::before {
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    bottom: 0;
    top: 0;
  }
}

.container {
  @include blurBackdrop();

  display: flex;
  justify-content: center;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  position: fixed;
  bottom: $default-horizon-line-height;

  &.accessibleHeight {
    bottom: $accessible-horizon-line-height;
  }

  .button {
    position: fixed;
    z-index: 3;
    bottom: $button-bottom;
  }
}

@include scale-for-4k-media-query {
  .container {
    bottom: scaleMediumDouble($default-horizon-line-height);

    &.accessibleHeight {
      bottom: scaleMediumDouble($accessible-horizon-line-height);
    }

    .button {
      bottom: scaleMediumDouble($button-bottom);
    }
  }
}
