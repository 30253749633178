@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$qr-code-width: 140px;
$description-font-size: 18px;
$description-margin: 20px 0 44px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      .qrCode {
        width: call(get-function($scaleFunction), $qr-code-width);
      }

      .description {
        font-size: call(get-function($scaleFunction), $description-font-size);
        margin: call(get-function($scaleFunction), $description-margin);
      }
    }
  }
}

.description {
  color: #212121;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.18px;
}

.dark {
  .qrCode {
    background: #fff;
  }

  .description {
    color: #fff;
  }
}

.light {
  .qrCode {
    color: #212121;
  }

  .description {
    color: #212121;
  }
}

@include buildSizes();

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
