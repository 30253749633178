@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$btn-padding: 16.65px 0;
$btn-min-width: 440px;
$btn-text-size: 22px;
$btn-svg-size: 24px;
$btn-svg-margin-right: 12px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      padding: call(get-function($scaleFunction), $btn-padding);
      min-width: call(get-function($scaleFunction), $btn-min-width);

      .text {
        font-size: call(get-function($scaleFunction), $btn-text-size);
      }

      .svg {
        margin-right: call(get-function($scaleFunction), $btn-svg-margin-right);
        width: call(get-function($scaleFunction), $btn-svg-size);
        height: call(get-function($scaleFunction), $btn-svg-size);
      }
    }
  }
}

@mixin buildColors($color, $bg) {
  background-color: $bg;
  color: $color;
}

.button {
  border-radius: 99px;
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.22px;
  }

  &.light {
    @include buildColors(#212121, #fff);
  }

  &.dark {
    @include buildColors(#fafafa, #212121);
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .button {
    @include buildSizes($doubled-sizes);
  }
}
