@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$continue-btn-margin: 24px 0 12px;
$countdown-container-width: 100px;
$countdown-font-size: 44px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .#{$component-size} {
      &.continueButton {
        margin: call(get-function($scaleFunction), $continue-btn-margin);
      }

      &.countDownTextContainer {
        width: call(get-function($scaleFunction), $countdown-container-width);
        height: call(get-function($scaleFunction), $countdown-container-width);

        .countDownText {
          font-size: call(get-function($scaleFunction), $countdown-font-size);
        }
      }
    }
  }
}

.countDownTextContainer {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .countDownText {
    text-align: center;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.44px;
  }
}

/* themes */
.dark {
  &.countDownTextContainer {
    background-color: #333333;

    .countDownText {
      color: #fff;
    }
  }
}

.light {
  &.countDownTextContainer {
    background-color: rgba(33, 33, 33, 0.08);

    .countDownText {
      color: #212121;
    }
  }
}

@include buildSizes();

.recentreButton {
  svg {
    color: #3c78ff;
  }
}

@include scale-for-4k-media-query {
  @include buildSizes($doubled-sizes);
}
