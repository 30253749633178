@import-normalize; /* bring in normalize.css styles */
@import url("./fonts/fonts.css");

* {
  box-sizing: border-box;
  user-select: none;
  cursor: none !important;
}

*:focus,
*:active {
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-control-container .mapboxgl-ctrl:not(.mapboxgl-ctrl-attrib) {
  display: none !important;
}

.mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  font-size: 12px;
  padding: 0 5px;
}

.mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib a {
  pointer-events: none;
}

.mapboxgl-control-container
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib
  .mapboxgl-ctrl-attrib-inner {
  line-height: 1.5;
}

.mapboxgl-popup {
  max-width: none !important;
}

.mapboxgl-popup .mapboxgl-popup-content {
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.light.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.light.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.light.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #ffffff;
}

.light.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #ffffff;
}

.light.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.light.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.light.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #ffffff;
}

.light.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #ffffff;
}

.light.expanded.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #ff5a00;
}

.light.expanded.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #ff5a00;
}

.light.expanded.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #ff5a00;
}

.dark.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.dark.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.dark.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #212121;
}

.dark.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #212121;
}

.dark.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.dark.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.dark.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #212121;
}

.dark.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #212121;
}

.dark.expanded.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #ff5a00;
}

.dark.expanded.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #ff5a00;
}

.dark.expanded.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #ff5a00;
}

@media (min-width: 2160px) {
  .mapboxgl-control-container .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    font-size: 24px;
    padding: 0 10px;
  }
}
