@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$border-radius: 10px;
$size: 48px;
$font-size: 18px;
$active-floor-size: 40px;
$poi-dot-size: 10px;
$poi-right: -10px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      width: call(get-function($scaleFunction), $size);

      .listItem {
        height: call(get-function($scaleFunction), $size);
        font-size: call(get-function($scaleFunction), $font-size);
      }

      .userOnFloor {
        &::before {
          width: call(get-function($scaleFunction), $active-floor-size);
          height: call(get-function($scaleFunction), $active-floor-size);
        }
      }

      .poiOnFloor {
        &::after {
          width: call(get-function($scaleFunction), $poi-dot-size);
          height: call(get-function($scaleFunction), $poi-dot-size);
          right: call(get-function($scaleFunction), $poi-right);
        }
      }
    }
  }
}

@mixin buildColors($bg, $color, $active-bg) {
  color: $color;
  background-color: $bg;

  .active {
    background: $active-bg;
    color: $color;

    &:first-child {
      border-radius: $border-radius $border-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }

  .userOnFloor {
    color: #fff;
  }

  .poiOnFloor {
    &::after {
      border: 5px solid $bg;
    }
  }
}

.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  border-radius: $border-radius;
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);

  .listItem {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-family: "IBM Plex Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.18px;
    position: relative;
  }

  .userOnFloor {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      border-radius: 100%;
      background: #3c78ff;
      z-index: -1;
    }
  }

  .poiOnFloor {
    &::after {
      content: "";
      position: absolute;
      border-radius: 100%;
      background: #f5642d;
      z-index: 1;
    }
  }

  /* Light theme */
  &.light {
    @include buildColors(#fff, #212121, rgba(33, 33, 33, 0.08));
  }

  /* Dark Theme */
  &.dark {
    @include buildColors(#212121, #fff, rgba(255, 255, 255, 0.16));
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
