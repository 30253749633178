@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$control-left-gap: 30px;
$control-right-gap: 40px;
$control-between: 12px;

$search-height-padder: 516px;
$search-results-margin: 0 0 12px;

$quick-search-tags-container-height: 103px;

$attribution-share-container-padding: 20px 0 8px;

$share-button-margin-bottom: 18.5px;

$logo-size: 6px;
$logo-margin-right: 2px;

$attribution-margin-left: 8px;
$attribution-font-size: 8px;

@mixin buildCompassSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      right: call(get-function($scaleFunction), $control-right-gap);
      top: call(get-function($scaleFunction), $control-right-gap);
    }
  }
}

@mixin buildControlSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .controlContainer {
      &.#{$component-size} {
        .controlTopMargin {
          margin-top: call(get-function($scaleFunction), $control-between);
        }
      }
    }

    .controlLeftContainer {
      &.#{$component-size} {
        left: call(get-function($scaleFunction), $control-left-gap);
      }
    }

    .controlRightContainer {
      &.#{$component-size} {
        right: call(get-function($scaleFunction), $control-right-gap);
      }
    }
  }
}

@mixin buildSearchSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    .searchHeightPadder {
      &.#{$component-size} {
        height: call(get-function($scaleFunction), $search-height-padder);
      }
    }

    .searchResultsContainer {
      &.#{$component-size} {
        margin: call(get-function($scaleFunction), $search-results-margin);
      }
    }
  }
}

@mixin buildFooterComponentSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .quickSearchTagsContainer {
        height: call(
          get-function($scaleFunction),
          $quick-search-tags-container-height
        );
      }

      .attributionShareContainer {
        padding: call(
          get-function($scaleFunction),
          $attribution-share-container-padding
        );

        .content {
          .shareButton {
            margin-bottom: call(
              get-function($scaleFunction),
              $share-button-margin-bottom
            );
          }

          .attribution {
            font-size: call(
              get-function($scaleFunction),
              $attribution-font-size
            );

            .logo {
              height: call(get-function($scaleFunction), $logo-size);
              width: call(get-function($scaleFunction), $logo-size);
              margin-right: call(
                get-function($scaleFunction),
                $logo-margin-right
              );
            }

            span:last-child {
              margin-left: call(
                get-function($scaleFunction),
                $attribution-margin-left
              );
            }
          }
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .primaryMap {
    position: relative;
    flex: 1;

    .primaryCompassContainer {
      position: absolute;

      @include buildCompassSizes();
    }

    .controlContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      z-index: 1;
    }

    @include buildControlSizes();
  }

  .searchWrapper {
    &.accessibleHeight {
      .animatedWrapper {
        transform: translateY(0);
      }
    }

    .animatedWrapper {
      transform: translateY(21%);
    }

    .searchHeightPadder {
      display: flex;
      align-items: flex-end;
    }

    .searchResultsContainer {
      z-index: 3;
    }

    @include buildSearchSizes();
  }

  .footerContainer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .searchContainer {
      margin: 0 auto;
    }

    .quickSearchTagsContainer {
      background-image: linear-gradient(#ffffff00, #ffffffe5);
    }

    .attributionShareContainer {
      width: 100%;
      color: #212121;
      font-family: "IBM Plex Sans";
      background: #ffffffe5;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .attribution {
          font-weight: 450;
          letter-spacing: 0.08px;
          line-height: 24px;
          margin: 0 auto;
        }
      }
    }

    @include buildFooterComponentSizes();
  }
}

@include scale-for-4k-media-query {
  .container {
    .primaryMap {
      .primaryCompassContainer {
        @include buildCompassSizes($doubled-sizes);
      }

      @include buildControlSizes($doubled-sizes);
    }

    .searchWrapper {
      @include buildSearchSizes($doubled-sizes);
    }

    .footerContainer {
      @include buildFooterComponentSizes($doubled-sizes);
    }
  }
}
