@import "../../../../scss/newScaleFunctions.scss";
@import "../../../../scss/common.scss";

$container-size: 668px;
$font-size: 22px;
$border-radius: 24px;
$input-padding: 18px 24px 15px;

$clear-icon-size: 18.5px;
$icon-top: 22.5px;
$icon-right: 28.65px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      width: call(get-function($scaleFunction), $container-size);
      border-radius: call(get-function($scaleFunction), $border-radius);

      .input {
        padding: call(get-function($scaleFunction), $input-padding);
        font-size: call(get-function($scaleFunction), $font-size);
        border-radius: call(
          get-function($scaleFunction),
          $border-radius $border-radius 0 0
        );
      }

      .closeIcon {
        top: call(get-function($scaleFunction), $icon-top);
        right: call(get-function($scaleFunction), $icon-right);

        > svg {
          width: call(get-function($scaleFunction), $clear-icon-size);
          height: call(get-function($scaleFunction), $clear-icon-size);
        }
      }
    }
  }
}

@mixin buildColors($color, $bg, $placeholder-color) {
  .input,
  .closeIcon {
    background: $bg;
    color: $color;

    &::placeholder {
      color: $placeholder-color;
    }
  }
}

.container {
  position: relative;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  max-width: 100%;
  box-shadow:
    0px 0px 8px 0px rgba(0, 0, 0, 0.04),
    0px 12px 32px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;

  .input {
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
    width: 100%;
    outline: none;
    border: none;
    text-align: center;
    pointer-events: none;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.22px;
  }

  .closeIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: none;
    padding: 0;
  }

  &.light {
    @include buildColors(#212121, #fff, #9e9e9e);
  }

  &.dark {
    @include buildColors(#fafafa, #212121, #575757);
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
