@import "../../scss/common.scss";
@import "../../scss/newScaleFunctions.scss";

$sub-text-content-padding: 2px 0;
$title-font-size: 18px;
$text-font-size: 14px;

$image-border-radius: 8px;
$image-margin-right: 16px;
$image-size: 96px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      .textContent {
        padding: call(get-function($scaleFunction), $sub-text-content-padding);
      }

      .title {
        font-size: call(get-function($scaleFunction), $title-font-size);
      }

      .text {
        font-size: call(get-function($scaleFunction), $text-font-size);
      }

      .imageContainer {
        border-radius: call(get-function($scaleFunction), $image-border-radius);
        margin-right: call(get-function($scaleFunction), $image-margin-right);
        min-width: call(get-function($scaleFunction), $image-size);
        max-width: call(get-function($scaleFunction), $image-size);
        height: call(get-function($scaleFunction), $image-size);

        .image {
          width: call(get-function($scaleFunction), $image-size);
          height: call(get-function($scaleFunction), $image-size);
        }
      }
    }
  }
}

@mixin buildColors(
  $bg,
  $color,
  $subText-color,
  $image-bg,
  $image-border-color
) {
  background-color: $bg;

  .textContent {
    color: $color;

    .title {
      &.titleSearch span {
        color: #3c78ff;
      }
    }

    .category,
    .floor {
      color: $subText-color;
    }

    .openingTimes {
      &.closed {
        color: #d32f2f;
      }
    }
  }

  .imageContainer {
    background: $image-bg;
    border: 2px solid $image-border-color;
  }
}

.container {
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  width: 100%;

  .textContent {
    width: 90%;
    overflow: hidden;

    .title {
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.18px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text {
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.14px;
    }
  }

  .imageContainer {
    overflow: hidden;

    .image {
      object-fit: cover;
    }
  }

  &.light {
    @include buildColors(#fff, #212121, #606060, #f5f5f5, #eee);
  }

  &.dark {
    @include buildColors(#212121, #fff, #fafafa, #444444, #444444);
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
