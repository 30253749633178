@import "../../../../../../scss/newScaleFunctions.scss";
@import "../../../../../../scss/common.scss";

$border-radius: 24px;
$container-padding: 20px;
$key-gap: 8px;

$button-width: 48px;
$backspace-width: 64px;
$spacebar-width: 328px;
$row-four-column-1-width: $button-width * 7 + $key-gap * 6;

$button-font-size: 25px;
$spacebar-font-size: 21px;

$arrow-key-icon-size: 18.5px;
$backspace-key-icon-size: 28px;

$row-two-margin: 0 38px;
$row-three-margin: 0 66px;
$row-four-margin-left: 94px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      gap: call(get-function($scaleFunction), $key-gap);
      padding: call(get-function($scaleFunction), $container-padding);
      border-radius: call(
        get-function($scaleFunction),
        0 0 $border-radius $border-radius
      );

      button {
        width: call(get-function($scaleFunction), $button-width);
        font-size: call(get-function($scaleFunction), $button-font-size);
      }

      .rowOne {
        .backspaceKey {
          width: call(get-function($scaleFunction), $backspace-width);

          > svg {
            width: call(get-function($scaleFunction), $backspace-key-icon-size);
          }
        }
      }

      .rowTwo {
        margin: call(get-function($scaleFunction), $row-two-margin);
      }

      .rowThree {
        margin: call(get-function($scaleFunction), $row-three-margin);
      }

      .rowFour {
        .columnOne {
          margin-left: call(
            get-function($scaleFunction),
            $row-four-margin-left
          );
          width: call(get-function($scaleFunction), $row-four-column-1-width);
        }

        .columnTwo {
          margin-left: call(get-function($scaleFunction), $button-width);
        }

        .arrowKey {
          > svg {
            width: call(get-function($scaleFunction), $arrow-key-icon-size);
            height: call(get-function($scaleFunction), $arrow-key-icon-size);
          }
        }
      }

      .rowFive {
        .spaceKey {
          width: call(get-function($scaleFunction), $spacebar-width);
          font-size: call(get-function($scaleFunction), $spacebar-font-size);
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;

  .row,
  .column {
    display: flex;
    justify-content: space-between;
  }

  .rowFour {
    .columnTwo {
      flex: 1;
    }
  }

  .rowFive {
    margin: 0 auto;
  }

  &.light {
    background: #f1f1f1;
  }

  &.dark {
    background: #444444;
  }

  @include buildSizes();
}

@media (min-width: 2160px) {
  .container {
    @include buildSizes($doubled-sizes);
  }
}
