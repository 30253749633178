@import "../../scss/newScaleFunctions.scss";
@import "../../scss/common.scss";

$padding: 11.41px 21px;
$font-size: 18px;
$icon-size: 18px;
$icon-margin: 11.5px;

@mixin buildSizes($component-sizes: $regular-sizes) {
  @each $component-size, $scaleFunction in $component-sizes {
    &.#{$component-size} {
      padding: call(get-function($scaleFunction), $padding);
      font-size: call(get-function($scaleFunction), $font-size);

      .icon {
        height: call(get-function($scaleFunction), $icon-size);
        width: call(get-function($scaleFunction), $icon-size);
      }

      .leftIcon {
        margin-right: call(get-function($scaleFunction), $icon-margin);
      }

      .rightIcon {
        margin-left: call(get-function($scaleFunction), $icon-margin);
      }
    }
  }
}

@mixin common-button-styles {
  &:disabled {
    background: rgba(33, 33, 33, 0.12);
    cursor: auto;
    color: #9e9e9e;
    pointer-events: none;
  }

  &.rounded {
    border-radius: 100px;
  }

  &.outlined {
    background: transparent;

    &:disabled {
      color: #9e9e9e;
      cursor: auto;
      pointer-events: none;
      background: transparent;
    }
  }
}

@mixin buildButtonColors($bg, $color, $active, $light: true) {
  background: $bg;
  color: $color;

  &.outlined {
    @if $light {
      border: 2px solid #e0e0e0;
    } @else {
      border: 2px solid #606060;
    }

    color: $bg;

    &:active {
      background: $active;
    }

    &:disabled {
      border: 2px solid #9e9e9e;
    }
  }
}

.button {
  color: #fff;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.18px;
  font-family: "IBM Plex Sans";
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 22px;
  font-weight: 500;

  // Light Theme
  &.light {
    &.blue {
      @include buildButtonColors(#3c78ff, #fff, #3c78ff14);
    }

    &.black {
      @include buildButtonColors(#212121, #fff, #2121210a);
    }

    &.white {
      @include buildButtonColors(#fff, #212121, #ffffff0a);
    }

    @include common-button-styles;
  }

  // Dark Theme
  &.dark {
    &.blue {
      @include buildButtonColors(#3c78ff, #212121, #3c78ff29, false);
    }

    &.black {
      @include buildButtonColors(#212121, #fff, #ffffff14, false);
    }

    &.white {
      @include buildButtonColors(#fff, #212121, #ffffff14, false);
    }

    @include common-button-styles;
  }

  @include buildSizes();
}

@include scale-for-4k-media-query {
  .button {
    @include buildSizes($doubled-sizes);
  }
}
